@use 'sass:map';
@use '../base/variables';
@use '../base/mixins';

$form-item-margin-bottom: 3.75rem;
$error-message-margin-top: 0.625rem;
$box-shadow: 0 0 10px #cbd2d2;

.grecaptcha-badge {
  display: none;
}

.gdds {
  form {
    .consent-form-checkbox {
      margin: -1.25rem 0;
    }
  }
}

form {
  hr {
    margin-bottom: 3.75rem;
  }

  .background__light-grey & {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .form-item,
  .form-items {
    margin-bottom: $form-item-margin-bottom;

    &__error-message {
      margin-top: $error-message-margin-top;
      font-size: 0.75rem;
      line-height: 1rem;
      color: map.get(variables.$foundation-palette, alert);
      text-align: left;
      min-height: $form-item-margin-bottom - $error-message-margin-top;
      margin-bottom: -$form-item-margin-bottom;

      .web20-icon-close {
        margin-left: 0.5rem;
        color: map.get(variables.$foundation-palette, alert);
      }

      &:empty {
        display: none;
      }
    }

    &.consent-form-checkbox {
      margin-bottom: 0.9375rem;

      label span {
        margin-bottom: 0.625rem;
      }

      label:last-of-type span {
        margin-bottom: 0;
      }
    }
  }

  .dependency-dropdown {
    margin-bottom: 2.75rem;

    &:last-of-type {
      margin-bottom: 3.75rem;
    }

    & > .form-item {
      margin-bottom: 0;
    }

    .form-item__error-message {
      margin-bottom: -2.75rem;
      min-height: 2.125rem;
    }
  }

  // Wrapper for single elements like text inputs and textareas
  .form-item {
    position: relative; // Needed for floating labels

    & > label {
      & > span {
        color: variables.$gb_grey_400;
        left: 1.375rem;
        pointer-events: none;
        position: absolute;
        top: 0.75rem;
        transition:
          transform variables.$gb_transitionFast variables.$gb_transitionTiming,
          font-size variables.$gb_transitionFast variables.$gb_transitionTiming;
        z-index: 10;
      }
    }

    &__file {
      margin-right: 0.625rem;
      font-size: 0.8125rem;
    }

    & > label.form-item__file-upload {
      display: inline-block;

      > span {
        position: relative;
        left: 0;
        top: 0;
      }

      &.button--background-primary > span {
        color: variables.$gb_white;
      }

      &.button--background-secondary > span {
        color: variables.$gb_black;
      }
    }

    &--button {
      width: 100%;
      float: left;
      margin-bottom: 2rem;
      margin-right: 0;
      display: flex;

      @include mixins.breakpoint(medium) {
        width: auto;
        margin-right: 2rem;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--gb-brand-primary-dark);
        height: 100%;
        transition: width 0.2s ease-in;
        max-width: 100%;
      }

      .upload-button--text {
        z-index: 10;
      }

      &.upload-icon-animation {
        .icon-upload {
          &__icon,
          &::before,
          &::after {
            animation: moveup 0.8s linear infinite;
          }
        }
      }

      &.upload-icon-finished {
        .icon-upload {
          &__wrapper {
            border-bottom: none;
          }

          &__icon {
            animation: transalteIcon 1.5s forwards;

            &::before {
              animation: translateBefore 1.5s forwards;
            }
            &::after {
              animation: translateAfter 1.5s forwards;
            }
          }
        }
      }

      .icon-upload {
        &__wrapper {
          overflow: hidden;
          display: block;
          position: relative;
          z-index: 1;
          height: 16px;
          width: 12px;
          margin-right: 5px;
          border-bottom: 1px solid variables.$gb_white;
        }

        &__icon {
          width: 1.3px;
          height: 12px;
          background-color: variables.$gb_white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &::before,
          &::after {
            content: '';
            top: 0;
            border-bottom-style: 0;
            width: 1.2px;
            height: 6px;
            position: absolute;
            background-color: variables.$gb_white;
          }

          &::before {
            left: 2px;
            transform: rotate(315deg);
          }

          &::after {
            right: 2px;
            transform: rotate(45deg);
          }
        }
      }
    }

    &__datepicker .react-datepicker__input-container:after {
      @include mixins.web20-icon('calendar');
    }

    &__timepicker .react-datepicker__input-container:after {
      @include mixins.web20-icon('service-clock');
    }

    &--filled label > span {
      font-size: 0.5625rem;
      transform: translateY(-0.625rem);
    }

    &--empty label > span {
      font-size: 0.875rem;
      transform: translateY(0);
    }

    &--error {
      input[type='text'],
      input[type='email'],
      input[type='date'],
      input[type='number'],
      input[type='password'],
      textarea,
      select {
        border-color: map.get(variables.$foundation-palette, 'alert');
      }
    }

    &--disabled {
      .dropdown__trigger {
        background-color: variables.$gb_grey_050;
        cursor: default;
        color: variables.$gb_grey_400;

        select {
          &:hover,
          &:focus {
            cursor: default;
            box-shadow: none;
          }
        }
      }

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }

  // Wrapper for multiple elements that needs to be positioned
  // Should only be used for checkboxes and radiobuttons; if you need multiple text inputs aligned use the grid
  .form-items {
    position: relative; // Needed to collect all hidden checkbox and radio inputs

    &__error-message {
      margin-top: 0;
    }

    &--wrapped {
      display: flex;
      flex-wrap: wrap;
    }

    &--vertical {
      .form-items--wrapped {
        display: block;
      }
    }

    &--error {
      label {
        color: map.get(variables.$foundation-palette, 'alert');

        > span {
          color: map.get(variables.$foundation-palette, 'alert');
        }
      }
    }

    &--images {
      label {
        margin: 0 1.875rem 1.25rem 0;
      }

      input[type='checkbox'],
      input[type='radio'] {
        & + span {
          margin: 0;
          max-width: 10.625rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .image--aspect-ratio {
        min-width: 10.625rem;
        margin-bottom: 1.25rem;

        @include mixins.breakpoint(medium only) {
          min-width: 13.75rem;
        }
      }
    }
  }

  // Text input styles
  input[type='text'],
  input[type='email'],
  input[type='date'],
  input[type='number'],
  input[type='password'],
  textarea,
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    border: 1px solid variables.$gb_grey_200;
    cursor: pointer;
    display: block;
    width: 100%;

    &:hover,
    &:focus {
      box-shadow: $box-shadow;
    }
  }

  input[type='file'] {
    display: none;
  }

  input[type='text'],
  input[type='email'],
  input[type='date'],
  input[type='password'],
  textarea {
    padding: 0.6875rem 1.375rem;
  }

  input[type='number'] {
    padding: 0.6875rem 0.5rem 0.6875rem 1.375rem;
  }

  textarea {
    min-height: 9.375rem;
    resize: vertical;
  }

  // Select
  // We already have most of the styles inside the "_dropdown.scss"
  // so wie just adjust it a little bit
  .dropdown {
    cursor: pointer;

    &__trigger {
      background-color: variables.$gb_grey_050;
      border: 0;
      color: variables.$gb_grey_400;
      padding: 0;

      &::after {
        color: variables.$gb_black;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  // Checkbox styling
  input[type='checkbox'] {
    & + span::after {
      @include mixins.web20-icon('check');
      display: block;
      visibility: hidden;
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      font-size: 0.75rem;
    }

    &:hover {
      & + span {
        &:before {
          box-shadow: $box-shadow;
        }

        &::after {
          visibility: visible;
        }
      }
    }

    &:checked + span {
      &::before {
        background-color: var(--gb-brand-primary-light);
      }

      &::after {
        visibility: visible;
        color: variables.$gb_white;
      }
    }

    &:disabled {
      & + span {
        color: variables.$gb_grey_400;
        cursor: default;

        &::before {
          background-color: variables.$gb_grey_060;
        }
      }

      &:hover {
        & + span {
          &::before {
            box-shadow: none;
          }

          &::after {
            visibility: hidden;
          }
        }
      }
    }

    &:checked:disabled {
      & + span::after,
      &:hover + span::after {
        color: variables.$gb_grey_200;
        visibility: visible;
      }
    }
  }

  .form-items--tooltip {
    input[type='radio'] {
      & + span {
        margin-right: 10px;
      }
    }

    .c-tooltip {
      margin-right: 1.5625rem;
      position: relative;
      top: -2px;
    }

    .tooltip-info-icon {
      font-size: 1.3125rem;
      line-height: 1;
    }
  }

  .form-items--input-tooltip {
    display: block;
    padding-right: 50px;
    position: relative;

    input[type='text'] {
      & + span {
        margin-right: 10px;
      }
    }

    .tooltip-info-icon {
      font-size: 1.3125rem;
      line-height: 1;
    }

    .c-tooltip {
      position: absolute;
      margin: 0;
      right: 0;
      top: 3px;
      padding: 5px;
    }
  }

  select {
    padding: 0.75rem 0.9375rem 0.625rem 0.9375rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: variables.$gb_white;

    &:focus {
      outline: none;
    }
  }

  p.pBeforeConsent {
    margin-bottom: 0.9375rem;
  }
}

.inline-text {
  p {
    margin-bottom: 1.875rem;
  }
}

// Checkbox/Radiobutton styles
input[type='checkbox'],
input[type='radio'] {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  & + span {
    margin: 0 1.875rem 1.25rem 0;
    display: block;
    cursor: pointer;
    padding-left: 1.875rem;
    position: relative;

    &::before {
      background: variables.$gb_white;
      width: 1.25rem;
      height: 1.25rem;
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border: 0.0625rem solid variables.$gb_grey_200;
    }
  }
}

// Radiobutton styling
input[type='radio'] {
  & + span {
    &::before {
      border-radius: 50%;
    }

    &::after {
      background-color: variables.$gb_grey_400;
      width: 0.625rem;
      height: 0.625rem;
      display: block;
      visibility: hidden;
      content: '';
      position: absolute;
      top: 0.3125rem;
      left: 0.3125rem;
      border-radius: 50%;
    }
  }

  &:hover {
    & + span {
      &::before {
        box-shadow: $box-shadow;
        border-color: variables.$gb_grey_400;
      }

      &::after {
        visibility: visible;
      }
    }
  }

  &:checked + span {
    &::before {
      border-color: var(--gb-brand-primary-light);
    }

    &::after {
      background-color: var(--gb-brand-primary-light);
      visibility: visible;
    }
  }

  &:disabled {
    & + span {
      color: variables.$gb_grey_400;
      cursor: default;

      &::before {
        border-color: variables.$gb_grey_200;
      }

      &::after {
        background-color: variables.$gb_grey_200;
      }
    }

    &:hover {
      & + span {
        &::before {
          box-shadow: none;
        }

        &::after {
          visibility: hidden;
        }
      }
    }
  }

  &:checked:disabled {
    &:hover {
      & + span::after {
        visibility: visible;
      }
    }
  }
}
